import { Component } from "react";
import PropTypes from "prop-types";
import { TextField, ListItemText, ListItemIcon, Autocomplete } from "@mui/material";
import Svg from "../svg";
// services
import Gupport from "../../services/gupport";
import { sortAlphabetically } from "../../services/utils";

class GloginSelect extends Component {

	constructor(props) {
		super(props);

		this.state = {
			optionsLoading: false,
			options: []
		};
	}

	componentDidMount() {
		this.fetchGlogins();
	}

	fetchGlogins() {
		if (Gupport.getGlogins) {
			this.setState({
				optionsLoading: true
			});

			Gupport.getGlogins({}, (error, msg) => {
				if (!error && msg.payload.status === "ok") {
					this.setState({
						optionsLoading: false,
						options: GloginSelect.prepareGloginsData(msg.payload.data)
					});
				} else {
					this.setState({
						optionsLoading: false,
						options: []
					});
				}
			});
		}
	}

	static prepareGloginsData(data) {
		const options = data.map((item) => ({
			id: item.id,
			label: `${item.data.display_name} (${item.data.user_name}) [${item.data.user_id}]`,
			search: [item.data.user_id, item.data.user_name, item.data.display_name],
			primary: `${item.data.display_name} (${item.data.user_name})`,
			secondary: item.data.user_id
		}));

		options.sort((optionA, optionB) => (
			sortAlphabetically(optionA.label.toLowerCase(), optionB.label.toLowerCase())
		));

		return options;
	}

	render() {
		return (
			<div style={{ position: "relative" }}>
				<Autocomplete
					id="autocomplete_glogin"
					className="child-input"
					fullWidth={true}
					loading={this.state.optionsLoading}
					options={this.state.options}
					filterOptions={(options, state) => {
						if (state.inputValue === "") {
							return options;
						}

						const search = state.inputValue.toLowerCase();
						return options.filter((option) => (
							option.search.some((value) => (value.toLowerCase().includes(search)))
						));
					}}
					isOptionEqualToValue={(option, value) => (option.id === value.id)}
					value={this.state.options.find((option) => (option.id === this.props.userId)) ?? null}
					onChange={(event, value/*, reason, details*/) => (this.props.onSelecedUserChanged(value))}
					renderInput={(params) => (
						<TextField autoFocus={true} {...params} placeholder="Search Support-User" />
					)}
					renderOption={(props, option/*, state*/) => (
						<li {...props}>
							<ListItemIcon><Svg src="add/add-user.svg" /></ListItemIcon>
							<ListItemText primary={option.primary} secondary={option.secondary} />
						</li>
					)}
					style={{ paddingRight: "24px" }}
				/>
			</div>
		);
	}

}

GloginSelect.defaultProps = {
	userId: null
};

GloginSelect.propTypes = {
	userId: PropTypes.string,
	onSelecedUserChanged: PropTypes.func.isRequired
};

export default GloginSelect;
