import { Component } from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import {
	Paper,
	CircularProgress,
	Checkbox,
	Snackbar,
	Button,
	Collapse,
	ListItemButton,
	ListItemText,
	ListItemIcon,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogContentText,
	DialogActions,
	Table,
	TableBody,
	TableHead,
	TableCell,
	TableRow
} from "@mui/material";
import Svg from "../svg";
// services
import Gupport from "../../services/gupport";
import { merge, sortAlphabetically } from "../../services/utils";
import { icons } from "@local/theme";

class SupportUserTableAccess extends Component {

	constructor(props) {
		super(props);

		this.state = {
			loading: true,
			allTables: [],
			tables: [],
			buttonDisabled: true,
			openErrorDialog: false,
			openSnackbar: false,
			expanded: false
		};

		this.handleCheckboxToggled = this.handleCheckboxToggled.bind(this);
		this.handleToggleExpandedClick = this.handleToggleExpandedClick.bind(this);
		this.handleSaveClick = this.handleSaveClick.bind(this);
		this.handleDialogClose = this.handleDialogClose.bind(this);
		this.handleSnackbarClose = this.handleSnackbarClose.bind(this);
	}

	componentDidMount() {
		this.fetchTableNames();
	}

	componentDidUpdate(prevProps) {
		if (this.props.userId !== prevProps.userId || this.props.tableRead !== prevProps.tableRead || this.props.tableWrite !== prevProps.tableWrite) {
			this.setState({
				tables: SupportUserTableAccess.prepareTables(this.state.allTables, this.props.tableRead, this.props.tableWrite),
				buttonDisabled: true,
				openErrorDialog: false,
				openSnackbar: false
			});
		}
	}

	static prepareTables(allTables, tableRead, tableWrite) {
		return allTables.map((tableName) => ({
			name: tableName,
			visible: Gupport.user.table_read.includes(tableName),
			editable: Gupport.hasLevel("sup_user_write"),
			read: tableRead.includes(tableName),
			write: tableWrite.includes(tableName)
		}));
	}

	fetchTableNames() {
		Gupport.getTableNames({}, (error, msg) => {
			if (error || msg.payload.status === "error") {
				this.setState({
					loading: false
					// error: error || msg.payload.data
				});
			} else {
				const allTables = msg.payload.data.map((table) => (table.id)).sort(sortAlphabetically);

				this.setState({
					loading: false,
					allTables: allTables,
					tables: SupportUserTableAccess.prepareTables(allTables, this.props.tableRead, this.props.tableWrite)
				});
			}
		});
	}

	handleCheckboxToggled(value, type, oldRead, oldWrite, event, isInputChecked) { // eslint-disable-line max-params
		this.setState((prevState) => ({
			tables: prevState.tables.map((table) => (
				(value === table.name) ? merge(table, {
					read: (type === "read") ? isInputChecked : (oldWrite) ? table.read : true,
					write: (type === "write") ? isInputChecked : (oldRead) ? false : table.write
				}) : table
			)),
			buttonDisabled: false
		}));
	}

	handleToggleExpandedClick() {
		this.setState((prevState) => ({
			expanded: !prevState.expanded
		}));
	}

	handleSaveClick() {
		if (Gupport.saveGlogin) {
			Gupport.saveGlogin({
				supUserId: this.props.userId,
				table_read: this.state.tables.filter((table) => (table.read)).map((table) => (table.name)),
				table_write: this.state.tables.filter((table) => (table.write)).map((table) => (table.name))
			}, (error, msg) => {
				if (error || msg.payload.status === "error") {
					this.setState({
						openErrorDialog: true
					});
				} else {
					this.setState({
						buttonDisabled: true,
						openSnackbar: true
					});
				}
			});
		}
	}

	handleDialogClose() {
		this.setState({
			openErrorDialog: false
		});
	}

	handleSnackbarClose() {
		this.setState({
			openSnackbar: false
		});
	}

	render() {
		const { t } = this.props;
		return (
			<>
				<Paper style={{ marginTop: "16px" }}>
					<ListItemButton style={{ paddingTop: "12px", paddingBottom: "12px" }} onClick={this.handleToggleExpandedClick}>
						<ListItemText primary={t("supportUser.tableAccess")} />
						<ListItemIcon className="ListItemIcon-right">
							{this.state.expanded ? <icons.ExpandLess /> : <Svg src="navigation/arrowDropDown.svg" />}
						</ListItemIcon>
					</ListItemButton>
					<Collapse in={this.state.expanded} timeout="auto">
						{this.state.loading ? <CircularProgress /> :
							<>
								<Table>
									<TableHead>
										<TableRow>
											<TableCell style={{ width: "128px" }}>{t("supportUser.table")}</TableCell>
											<TableCell style={{ width: "72px" }}>{t("supportUser.read")}</TableCell>
											<TableCell style={{ width: "72px" }}>{t("supportUser.write")}</TableCell>
											<TableCell />
										</TableRow>
									</TableHead>
									<TableBody>
										{this.state.tables.filter((table) => (table.visible)).map((table) => (
											<TableRow key={table.name} className="last-row-no-border">
												<TableCell style={{ width: "128px" }}>
													{table.name}
												</TableCell>
												<TableCell style={{ width: "72px" }}>
													<Checkbox
														checked={table.read}
														disabled={!table.editable}
														onChange={this.handleCheckboxToggled.bind(this, table.name, "read", table.read, table.write)}
													/>
												</TableCell>
												<TableCell style={{ width: "72px" }}>
													<Checkbox
														checked={table.write}
														disabled={!table.editable}
														onChange={this.handleCheckboxToggled.bind(this, table.name, "write", table.read, table.write)}
													/>
												</TableCell>
												<TableCell />
											</TableRow>
										))}
									</TableBody>
								</Table>
								{Gupport.hasLevel("sup_user_write") && Gupport.saveGlogin &&
									<Button
										variant="contained"
										disabled={this.state.buttonDisabled}
										style={{ margin: "16px 0 12px 24px" }}
										onClick={this.handleSaveClick}
									>
										{t("supportUser.save")}
									</Button>
								}
							</>
						}
					</Collapse>
				</Paper>
				<Dialog
					fullWidth={true}
					open={this.state.openErrorDialog}
					onClose={this.handleDialogClose}
				>
					<DialogTitle>{t("supportUser.saveErrorTitle")}</DialogTitle>
					<DialogContent>
						<DialogContentText>
							{t("supportUser.saveTables")} {this.props.userId} {t("supportUser.saveErrorMsg")}
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button onClick={this.handleDialogClose}>{t("dialog.ok")}</Button>
					</DialogActions>
				</Dialog>
				<Snackbar
					open={this.state.openSnackbar}
					message={t("supportUser.changedSaved")}
					autoHideDuration={2000}
					onClose={this.handleSnackbarClose}
				/>
			</>
		);
	}

}

SupportUserTableAccess.defaultProps = {
	tableRead: null,
	tableWrite: null,
	userId: ""
};

SupportUserTableAccess.propTypes = {
	tableRead: PropTypes.arrayOf(PropTypes.string),
	tableWrite: PropTypes.arrayOf(PropTypes.string),
	userId: PropTypes.string,
	t: PropTypes.func.isRequired
};

export default withTranslation()(SupportUserTableAccess);
