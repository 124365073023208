import { Component, createRef } from "react";
import { Navigate } from "react-router-dom";
import PropTypes from "prop-types";
import { IconButton, CircularProgress } from "@mui/material";
// cmp
import withNavigateAndParams from "../cmp/withNavigateAndParams";
import ResponsivePagePanel from "../cmp/responsive-page-panel";
import GloginSelect from "../cmp/support-user/glogin-select";
import SupportUserInfo from "../cmp/support-user/support-user-info";
import SupportUserInvite from "../cmp/support-user/support-user-invite";
import Svg from "../cmp/svg";
// services
import Gupport from "../services/gupport";
import Accounts from "../services/accounts";
import { Storage, StorageKeys } from "../services/storage";
import Settings from "../services/settings";
import { muiTheme } from "@local/theme";

class SupportUsersPage extends Component {

	constructor(props) {
		super(props);

		let userId;
		if (this.props.params.userId) {
			Storage.set(StorageKeys.supportUserId, this.props.params.userId);
			userId = this.props.params.userId;
		} else if (Storage.get(StorageKeys.supportUserId)) {
			userId = Storage.get(StorageKeys.supportUserId);
		} else {
			userId = null;
		}

		this.state = {
			ready: Gupport.ready,
			userId: userId
		};

		this.refSearchBox = createRef();

		this.handleGupportReady = this.handleGupportReady.bind(this);
		this.handleAccountChanged = this.handleAccountChanged.bind(this);
		this.handleOpenNewWindow = this.handleOpenNewWindow.bind(this);
		this.handleUserAdded = this.handleUserAdded.bind(this);
		this.handleUserRemoved = this.handleUserRemoved.bind(this);
		this.handleSelecedUserChanged = this.handleSelecedUserChanged.bind(this);
	}

	componentDidMount() {
		Gupport.on("ready", this.handleGupportReady);
		Accounts.on("setDefault", this.handleAccountChanged);
	}

	componentDidUpdate(prevProps) {
		if (this.props.params.userId !== prevProps.params.userId && this.props.params.userId !== this.state.userId) {
			this.setState({
				userId: this.props.params.userId ?? null
			});
		}
	}

	componentWillUnmount() {
		Gupport.off("ready", this.handleGupportReady);
		Accounts.off("setDefault", this.handleAccountChanged);
	}

	handleGupportReady() {
		this.setState({
			ready: Gupport.ready
		});
	}

	handleAccountChanged(accountFor, account, isNewAccount) {
		if (accountFor === Settings.cluster.gupportWsUrl && isNewAccount) {
			this.clearSelectedUser();
		}
	}

	handleOpenNewWindow() {
		window.open(
			`support_user.html#/support/users/${this.state.userId}`,
			"_blank"
		);
	}

	handleUserAdded(userId) {
		this.refSearchBox.current.fetchGlogins(); // TODO
		this.setSelectedUser(userId);
	}

	handleUserRemoved(/*userId*/) {
		this.refSearchBox.current.fetchGlogins(); // TODO
		this.clearSelectedUser();
	}

	setSelectedUser(userId) {
		Storage.set(StorageKeys.supportUserId, userId);
		this.setState({
			userId: userId
		});
		this.props.navigate(`/support/users/${userId}`);
	}

	clearSelectedUser() {
		Storage.remove(StorageKeys.supportUserId);
		this.setState({
			userId: null
		});
		this.props.navigate("/support/users");
	}

	handleSelecedUserChanged(value) {
		if (value === null) {
			this.clearSelectedUser();
		} else {
			this.setSelectedUser(value.id);
		}
	}

	renderPage() {
		if (!this.state.ready) {
			return <CircularProgress />;
		}
		if (this.state.userId === null) {
			return null;
		}

		return <SupportUserInfo userId={this.state.userId} onUserRemoved={this.handleUserRemoved} />;
	}

	render() {
		if (this.props.params.userId === undefined && this.state.userId !== null) {
			return <Navigate to={`/support/users/${this.state.userId}`} />;
		}

		const title = this.state.ready
			? (
				<GloginSelect
					ref={this.refSearchBox}
					userId={this.state.userId}
					onSelecedUserChanged={this.handleSelecedUserChanged}
				/>
			)
			: null;

		const iconElementRight = (
			<>
				{Gupport.addGlogin && <SupportUserInvite onUserAdded={this.handleUserAdded} />}
				{this.props.separatePage ? null :
					<IconButton disabled={!this.state.userId} onClick={this.handleOpenNewWindow}>
						<Svg src="navigation/openNewWindow.svg" color={this.state.userId ? muiTheme.palette.primary.contrastText : "rgba(255,255,255,.7)"} />
					</IconButton>
				}
			</>
		);

		return (
			<ResponsivePagePanel
				activeRoute="/support/users"
				title={title}
				iconElementRight={iconElementRight}
				hideNavigationDrawer={this.props.separatePage}
				hideNavigationMenu={this.props.separatePage}
			>
				{this.renderPage()}
			</ResponsivePagePanel>
		);
	}

}

SupportUsersPage.defaultProps = {
	separatePage: false
};

SupportUsersPage.propTypes = {
	separatePage: PropTypes.bool,
	navigate: PropTypes.func.isRequired,
	params: PropTypes.shape({
		userId: PropTypes.string
	}).isRequired
};

export default withNavigateAndParams(SupportUsersPage);
